import { createRef, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import postService from "../services/postService";
import { Col, Container, Row, } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import { toast } from "react-toastify";
import SimilarShowsList from "../components/SimilarShowsList";
import { arrayMoveImmutable } from "array-move";
import Moment from "react-moment";
import TermsInput from "../components/ThemesInput";
import ShowNameInput from "../components/ShowNameInput";
import ShowTaxInput from "../components/ShowTaxInput";
import { config } from "../config"

const Single = ( { isNew } ) => {

  const params = useParams();
  const [post, setPost] = useState()
  const [postUpdate, setPostUpdate] = useState({ meta: {} })
  const [similarsList, setSimilarsList] = useState([])
  const newSimilarsRef = createRef();
  const newSimilarsTaxRef = createRef();
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      const { data: post } = await postService.getPost( params.id )
      console.log( post )
      setPost( post )
      if(post.meta && post.meta["tsl-similar-shows"]){
        let similars = post.meta["tsl-similar-shows"][0]
        console.log(similars)
        setSimilarsList( similars.split(",") )
      }
      setIsLoading(false)
    }
    if(!isLoading && !isNew){ fetchData(); }

    if(isNew){
      setPost({post_title: "", taxonomy: {genre: '', theme: ''}, meta: {}})
    }
// eslint-disable-next-line
  }, [])

  const setTerms = (terms, tax) => {
    console.log('setTerms..')
    console.log(terms)
    if(terms && Array.isArray(terms)){
      const termsNames = terms.map(t => { return (typeof t === "object") ? t[tax] : t })
      if(terms.length > 1){
        const newVal = termsNames.join(",")
        patchField({key:tax,value:newVal})
      } else if (termsNames.length === 1 ) {
        const newVal = termsNames[0]
        patchField({key:tax,value:newVal})
      }
    }
  }

  const patchField = ({key, value}) => {
    console.log( 'patch triggered..' )
    postUpdate[key] = value
    setPostUpdate(postUpdate)
    console.log(postUpdate)
  }

  const patchMetaField = ({meta, value}) => {
    console.log( 'patch triggered..' )
    postUpdate["meta"][meta] = value
    setPostUpdate(postUpdate)
    console.log(postUpdate)
  }

  const updateSimilars = ({oldIndex, newIndex}) => {
    if( oldIndex === newIndex ) return;
    let updatedList = arrayMoveImmutable(similarsList, oldIndex, newIndex);
    setSimilarsList(updatedList)
    console.log( 'similars updated..' )
    postUpdate["meta"]["tsl-similar-shows"] = updatedList.join(",")
    setPostUpdate(postUpdate)
    console.log(postUpdate)
  }

  const removeSimilar = (item) => {
    console.log(item)
    let updatedList = [...similarsList]
    updatedList.splice(updatedList.indexOf(item), 1)
    setSimilarsList(updatedList)
  }

  const sanitizeShowName = (showname) => {
    return showname.replace(/`/,"'").replace(/[^-A-Za-z0-9:!,+?&#'\\. ]/gi,'')
  }

  const onLaunchClick = (showInfo) => {
    if(showInfo.post_name){
      window.open(showInfo.post_name, "_blank");
    }
  }

  const extendSimilars = (newShow, inputRef) => {
    let newSimilars = newShow
    let updatedList = [...similarsList]
    updatedList = newSimilars.map(s => sanitizeShowName(s)).concat(updatedList)
    let uniqueList = [...new Set(updatedList)];
    setSimilarsList(uniqueList)
    console.log( 'similars updated..' )
    postUpdate["meta"]["tsl-similar-shows"] = updatedList.join(",")
    setPostUpdate(postUpdate)
    console.log(postUpdate)
    inputRef.current.clear();
  }

  const onClickUpdate = async () => {
    console.log('updating post..')
    console.log( postUpdate )
    const { status, data } = await postService.patchField( post.ID, postUpdate );
    console.log( data )
    if( 200 === status && data && data.ID ){
      toast.success("post saved.")
    }
  }

  const onClickSave = async () => {
    console.log('saving post..')
    console.log( postUpdate )
    const { status, data } = await postService.createPost( postUpdate );
    console.log( data )
    if( 200 === status && data && data.ID ){
      toast.success("post saved.")
      navigate(`/`);
    }
  }

  const onClickDelete = async () => {
    console.log('deleting post..')
    if( window.confirm(`Delete post: "${post.post_title}" ?`) ){
      const { status, data } = await postService.deletePost( post.ID );
      console.log( data )
      if( 200 === status && data && data.ID ){
        toast.success("post deleted")
        navigate("/");
      }
    }
  }

  return ( 
    <Container>
      <main>
        { post && 
          <div className="post">
            <Row> 
              <Col md={6}>
              <div>
                <div className="form-group mb-2">
                  {post.post_name && <small><a href={`${config.siteURL}/${post.post_name}`} target="new">{post.post_title}</a></small>}
                  <DebounceInput 
                    id="title"
                    className="form-control"
                    debounceTimeout={400}
                    onChange={event => patchField({key:'title', value: event.target.value})}
                    value={post.post_title}
                    />
                    <div className="mb-2"><small>Last Update: <Moment date={post.post_modified} fromNow /></small></div>
                </div>
                <div>
                  <TermsInput
                      placeholder="Genres.."
                      id="genres"
                      labelKey="genre"
                      selectedInput={post.taxonomy["genre"]}
                      taxonomy="genre"
                      onChange={t => setTerms(t, "genre")}
                    />
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="show-name" className="form-label">Show Name</label>
                  <DebounceInput 
                    id="show-name"
                    className="form-control"
                    debounceTimeout={400}
                    onChange={event => patchMetaField({meta:'tsl-show-name', value: event.target.value})}
                    value={post.meta['tsl-show-name']}
                    />
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="summary" className="form-label">Summary</label>
                  <DebounceInput 
                    id="summary"
                    className="form-control"
                    element="textarea"
                    rows="8"
                    debounceTimeout={1000}
                    onChange={event => patchMetaField({meta:'tsl-summary', value: event.target.value})}
                    value={post.meta["tsl-summary"]}
                    />
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="year" className="form-label">Year</label>
                  <DebounceInput 
                    id="year"
                    className="form-control"
                    debounceTimeout={400}
                    onChange={event => patchMetaField({meta:'nm-show-year', value: event.target.value})}
                    value={post.meta["nm-show-year"]}
                    />
                </div>
                <div className="form-group mb-2">
                  <div>
                    <TermsInput
                      placeholder="Themes.."
                      id="themes"
                      labelKey="theme"
                      minLength={3}
                      multiple
                      selectedInput={post.taxonomy["theme"]}
                      taxonomy="theme"
                      onChange={t => {setTerms(t, "theme");}}
                    />
                  </div>
                </div>
                
              </div>
            </Col>
            <Col md={6}>
              <div className="well p-2">
              <div className="input-group mb-3">
                <ShowNameInput
                  id="new-similars" 
                  className="form-control" 
                  placeholder="Add new.." 
                  aria-label="Similars" 
                  parentRef={newSimilarsRef} 
                  onChange={s=> extendSimilars(s, newSimilarsRef)} />
                <ShowTaxInput
                  id="new-similars" 
                  className="form-control" 
                  placeholder="Add new.." 
                  aria-label="Similars" 
                  tax="theme"
                  parentRef={newSimilarsTaxRef} 
                  onChange={st => extendSimilars([st[0].name], newSimilarsTaxRef)} />
              </div>
              <SimilarShowsList 
                items={similarsList} 
                onSortUpdate={updateSimilars}
                onRemoveItem={removeSimilar}
                onLaunchClick={onLaunchClick}
                />
              </div>
          </Col>
        </Row>

          <div className="toolbar p-3">
            <button className="btn btn-delete" onClick={onClickDelete}>Delete</button>
            { !isNew && <button className="btn float-end" onClick={onClickUpdate}>Update</button> }
            { isNew && <button className="btn float-end" onClick={onClickSave}>Save</button>}
          </div>
        </div>
        }
      </main>
    </Container>
  );
}

export default Single;