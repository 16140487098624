import { DebounceInput } from "react-debounce-input";

const Filters = ( { searchTerm, onSearchTermChange, onPrevPageClick, onNextPageClick, currentPage,maxPages,resultsCount, ...rest } ) => {

  return ( 
    <>
    <div className="toolbar d-flex justify-content-between px-3">
      <DebounceInput 
        type="text" 
        debounceTimeout={500}
        value={searchTerm}
        onChange={e => onSearchTermChange(e.target.value)} />

      <div className="d-flex py-3 align-content-center">
        <button className="btn btn-sm" onClick={e => onPrevPageClick()}>&laquo;</button> 
        <div className="p-2">{currentPage} / {maxPages}</div>
        <button className="btn btn-sm" onClick={e => onNextPageClick()}>&raquo;</button>
      </div>
    </div>
    <div>{resultsCount} items found.</div>
    </>
  );
}

export default Filters;