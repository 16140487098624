const InputField = ({name, label, error, ...rest}) => {
  return ( 
    <div className="field">
    <label htmlFor={name}>{label}</label>
      <input
        className={ error && 'has-error'}
        {...rest}
        name={name}
        id={name}
      />
    { error && <div className="error">{error}</div>}</div>
  );
}

export default InputField;