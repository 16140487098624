import React from 'react'
import Form from "./common/form";
const Joi = require('joi-browser');

export default class RegisterForm extends Form {

  state = {
    data: { email: "", password: "", displayName: "" },
    errors: {}
  };

  schema = {
    email: Joi.string()
      .email()
      .min(6)
      .max(65)
      .required()
      .label('Eposta'),
    password: Joi.string()
      .min(6)
      .max(24)
      .required()
      .label('Şifre'),
    displayName: Joi.string()
      .min(5)
      .max(65)
      .required()
      .label("Görünen Ad")
  }

  doSubmit = () => {
    // submit form
  }

  render() {

    return (
      <form className="form" onSubmit={this.handleSubmit}>
      { this.renderInput("email", "Eposta") }
      { this.renderInput("password", "Şifre", "password") }
      { this.renderInput("displayName", "Görünen Ad") }
      <div className="tac">
        { this.renderButton("Kaydol") }
      </div>
    </form>
    )
  }
}




