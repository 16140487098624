import { useMemo, useRef, useState } from "react";
import { Card, CardFooter, CardHeader } from "react-bootstrap";
import postService from "../services/postService";
import { Link } from "react-router-dom";

const ShowItem = ({name, onRemoveItem, onLaunchClick}) => {

  const loadingRef = useRef(false)
  const [showInfo, setShowInfo] = useState({
    showname: name,
    taxonomy: { genre: ["N/A"], network: ["N/A"] },
    meta: { "nm-show-year": "N/A" }
  })
  const [exists, setExists] = useState(false)
  const [statusClass, setStatusClass] = useState('status-loading')

  useMemo(() => { 
    async function fetchData() {
      loadingRef.current = true
      try{
        const res = await postService.getPostByShowName( name )
        if( res.status === 200 ){
          // console.log(res.data)
          if( res.data.post_status === 'draft' ){
            setStatusClass('status-draft')
          } else {
            setStatusClass('status-success')
          }
          setExists(true)
          setShowInfo( res.data )
        } else {
          setStatusClass('status-not-found')
          setExists(false)
        }
      } catch (err) {
        setStatusClass('status-failed')
        setExists(false)
      }
      
    }
    if(!loadingRef.current){ fetchData(); }
  }, [name])

  return ( 
    <Card className={ [
      'mb-2',
      statusClass
      ].join(' ') }>
      <CardHeader className="d-flex justify-content-between">
        <div>
          { exists && <Link to={`/${ showInfo?.post_name }`}><h4>{name}</h4></Link> }
          { !exists && <span>{name}</span> }
          <div className="s">{showInfo.meta["nm-show-year"]} / {showInfo.taxonomy["genre"]}</div>
        </div>
        <div>
        { exists && <button className="x" onClick={e => onLaunchClick(showInfo)}>O</button> }
          <button className="x" onClick={e => onRemoveItem(name)}>&times;</button>
        </div>
      </CardHeader>
      { exists && 
        <CardFooter className="xs">
          {showInfo.taxonomy["theme"]}
        </CardFooter>
      }
    </Card>

  );
}

export default ShowItem;