import React, { createContext, useMemo, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';

import './App.css';
import Layout from './pages/Layout';
import NotFound from './pages/NotFound';
import Single from './pages/Single';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Register from './pages/Register';
import RequireAuth from './components/common/RequireAuth';
export const UserContext = createContext(null)

function App() {

  const [user] = useState({})

  const account = useMemo(() => {
		return { user };
	}, [user]);

  return (
    <UserContext.Provider value={account}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />} >
            <Route index element={<Home />} />
            <Route path="/:id" element={
              <RequireAuth>
                <Single />
              </RequireAuth>} />
            <Route path="*" element={<NotFound />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/register" element={<Register />} />
            <Route path="/new" element={<Single isNew="true" />} />
            <Route path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>Not found</p>
                  </main>
                }
              />
          </Route>
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
    
  );
}

export default App;
