import { Link } from "react-router-dom";
import RegisterForm from "../components/RegisterForm";

const Register = () => {
  return ( 
    <div className="hero full">
      <div className="wrap">
        <div className="md-flex">
          <div className="md-c1_2 body">
            <h1>OtoAcil.net'e Hoşgeldiniz!</h1>
            <p>Ücretsiz ilan yayınlamak için hemen hesabınızı oluşturun.</p>
          </div>
          <div className="md-c1_2">

            <div className="bx p0 br w460 mx-auto mt mb">
              <div className="hdr act success flex">
                <div className="ps">Hesabınız var mı?</div>
                <div className="tar ps"><Link className="btn" to="/login">Giriş Yap</Link></div>
              </div>
          
              <div className="p1">
                <h2>Hoşgeldiniz</h2>
                <h5>E-posta adresiniz ile kaydolun</h5>
          
                <RegisterForm />
          
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  );
}

export default Register;