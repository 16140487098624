import React, { useEffect, useRef, useState } from 'react';
import PostGrid from '../components/PostGrid';
import postService from '../services/postService';
import Container from 'react-bootstrap/Container';
import Filters from '../components/Filters';

const Home = () => {

  const loadingRef = useRef(false)

  const [posts, setPosts] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [postCount] = useState(10)
  const [maxPages, setMaxPages] = useState(1)
  const [resultsCount, setResultsCount] = useState(0)
  const [searchTerm, setSearchTerm] = useState("")

  const nextPage = () => {
    let nextPageNumber = Math.min( currentPage + 1, maxPages )
    setCurrentPage( nextPageNumber )
  }

  const prevPage = () => {
    let prevPageNumber = Math.max( currentPage - 1, 1 )
    setCurrentPage( prevPageNumber )
  }

  useEffect(() => {
    async function fetchPosts(){
      loadingRef.current = true
      console.log('fetching posts..')
      const { data } = await postService.getPosts({currentPage, postCount, searchTerm})
      console.log(data.items)
      setPosts(data.items)
      setMaxPages(data.maxpages)
      setResultsCount(data.results)
      loadingRef.current = false
    }
    if(!loadingRef.current){ fetchPosts(); }
  }, [ currentPage, postCount, searchTerm ])

  return ( 
    <Container>

      <PostGrid posts={posts} />
      <Filters 
        className="toolbar px-2"
        searchTerm={searchTerm} 
        onSearchTermChange={setSearchTerm} 
        onNextPageClick={nextPage}
        onPrevPageClick={prevPage}
        currentPage={currentPage}
        maxPages={maxPages}
        resultsCount={resultsCount}
        />
    </Container>
  );
}

export default Home;