import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Header = () => {

  return ( 
    <React.Fragment>
    <div className="navbar sticky-top navbar-dark bg-dark mb-2">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">ShowsLike</Link>
        <Nav className="me-auto">
          <Link className="nav-link" to="/new">New</Link>
        </Nav>
      </div>
    </div>
    </React.Fragment>
  );
}

export default Header;