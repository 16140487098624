import axios from "axios";
import { toast } from "react-toastify";
import logger from "./logService";

// log unexpected errors & display on toast
axios.interceptors.response.use(null, error => {

  const expectedError = 
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if(!expectedError){
    if("Network Error" === error.message){
      toast.error("Can't connect right now.")
    } else {
      logger.log(error);
      toast.error("An unexpected error occured.");
    }
  }

  return Promise.reject(error);

});

const httpService = {
  get: axios.get,
  put: axios.put,
  patch: axios.patch,
  post: axios.post,
  delete: axios.delete
}

export default httpService



