import {
  useLocation,
  Navigate,
} from "react-router-dom";
import { useAuth } from "../../services/authService"

export default function RequireAuth({children}) {
  const [user] = useAuth();
  let location = useLocation(); 

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

