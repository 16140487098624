import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authService from "../services/authService";


const Logout = () => {

  const navigate = useNavigate();
  
  useEffect(() => {
    // logout current user
    authService.logout()
    // navigate to home screen
    navigate("/");

    return () => {}
  }, [navigate])

  return (
    <></>
  );
}




export default Logout;