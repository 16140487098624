import { config } from "../config"
import { appendParams } from "../util/util"
import httpService from "./httpService"

const apiEndpoint = config.apiEndpoint + "/gs2wp/v1/terms"

export const getTerms = async ( params ) => {
  console.log(apiEndpoint + appendParams( params ))
  return httpService.get( apiEndpoint + appendParams( params ) )
}

const termsService = {
  getTerms
}

export default termsService


