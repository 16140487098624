import React, { Component } from 'react'
import InputField from './inputField';
import Select from './select';
const Joi = require('joi-browser');

export default class Form extends Component {

  state = {
    data: {},
    errors: {}
  }

  validate = () => {
    const { error } = Joi.validate(this.state.data, this.schema, { abortEarly: false });
    if(!error) return null;
    const errors = {};
    for(let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  }

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const objSchema = { [name]: this.schema[name] };
    const { error } = Joi.validate( obj, objSchema );
    if(!error) return null;
    return error.details[0].message;
  }

  onChange = ({ target: input }) => {
    const errors = { ...this.state.errors }
    const errorMessage = this.validateProperty(input)
    if( errorMessage ) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setErrors(this.validate())
    this.doSubmit()
  }

  renderInput = (name, label, type="text") => {
    const{ data, errors } = this.state;

    return (
      <InputField
        type={type}
        label={label}
        name={name}
        value={data[name]}  
        onChange={(e) => this.onChange(e)}
        error={errors[name] ? errors[name]: null}
        required />
    );
  }

  renderButton = (label) => {
    return (
      <button 
        disabled={this.validate()}
        className="lg" 
        type="submit">{label}</button>
    )
  }

  renderSelect = (name, label, options) => {
    const{ data, errors } = this.state;

    return(
      <Select
        label={label}
        name={name}
        options={options}
        value={data[name]}  
        onChange={(e) => this.onChange(e)}
        error={errors[name] ? errors[name]: null} />
    )
  }

}
