const login = async (email, password) => {};
const logout = async () => {};
export function useAuth(){

  return [true, false, false]
}

const authService = {
  login,
  logout,
  useAuth,
}

export default authService