import { Link } from "react-router-dom";
import LoginForm from "../components/LoginForm";

const Login = () => {

  return ( 
    <div className="hero full">
    <div className="wrap">
      <div className="md-flex">
        <div className="md-c1_2 body">
          <h1>OtoAcil.net'e Hoşgeldiniz!</h1>
          <p>Ücretsiz ilan yayınlamak için hemen hesabınızı oluşturun.</p>
        </div>
        <div className="md-c1_2">

          <div className="bx p0 w460 br mx-auto mt mb">
            <div className="hdr act flex">
              <div className="ps">Hesabınız yok mu?</div>
              <div className="tar ps"><Link className="btn" to="/register">Hesap Oluştur</Link></div>
            </div>
        
            <div className="p1">
              <h2>Giriş</h2>
              <div className="tac">
                <LoginForm buttonLabel="Login" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    </div>

  );
}

export default Login;