import { config } from "../config"
import { appendParams } from "../util/util"
import httpService from "./httpService"

const apiEndpoint = config.apiEndpoint + "/gs2wp/v1/posts"
const cachedShowData = {}

export const getPost = async (slug) => {
  return httpService.get( apiEndpoint + `/${slug}` )
}

export const getPostByShowName = async (showname, refresh=false) => {
  if(!cachedShowData[showname] && !refresh){
    const encodedShowName = encodeURIComponent( showname ).replace(/'/g, "%27").replace(/!/g,"%21")
    // console.log(encodedShowName)
    const res = httpService.get( apiEndpoint + `/byname/${encodedShowName}`, { validateStatus: false } )
    cachedShowData[showname] = res
    return res
  } else {
    return cachedShowData[showname]
  }
}

export const getPosts = async ( params ) => {
  console.log(apiEndpoint + appendParams( params ))
  return httpService.get( apiEndpoint + appendParams( params ) )
}


export const getPostsByTax = async ( params ) => {
  console.log(apiEndpoint + 'bytax' + appendParams( params ))
  return httpService.get( apiEndpoint + 'bytax' + appendParams( params ) )
}


export const createOrUpdate = async ( post ) => {
  if(post.id){
    return updatePost(post)
  } else {
    return createPost(post)
  }
}

export const patchField = async( post_id, update ) => {
  return httpService.patch( apiEndpoint + `/${post_id}`, update )
}

export const updatePost = async ( post ) => {
  return httpService.post( apiEndpoint + `/${post.id}`, post )
}

export const deletePost = async ( post_id ) => {
  return httpService.delete( apiEndpoint + `/${post_id}` )
}

export const createPost = async ( post ) => {
  return httpService.post( apiEndpoint, post  )
}


const postService = {
  getPost,
  getPosts,
  createPost,
  updatePost,
  deletePost,
  createOrUpdate,
  patchField,
  getPostByShowName,
  getPostsByTax,
}

export default postService