import { Link } from "react-router-dom";
import { Card, CardBody } from "react-bootstrap";
import Moment from "react-moment";

const Post = ({ post }) => {
  const { post_name, post_title, post_modified } = post

  return (
    <Card className="post">
      <CardBody className="">
        <div>
          <Link to={`/${ post_name }`}><h4>{ post_title }</h4></Link>
          <small><Moment date={post_modified} fromNow /></small>
        </div>
      </CardBody>
    </Card>
  );
}

export default Post;
