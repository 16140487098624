import { useState } from "react";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import postService from "../services/postService";



const ShowTaxInput = ({parentRef, tax, ...rest}) => {

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const turnOffFilterBy = () => true;

  const handleSearch = async (query) => {
    setIsLoading(true)
    const res = await postService.getPostsByTax({taxonomy:tax, term:query})
    if( res && res.data?.items ){
      const posts = res.data.items
      const shows = posts.map(p => { return {name: p.meta['tsl-show-name'][0], term: p.term}})
      console.log(shows)
      setOptions(shows)
      setIsLoading(false)
    }
  }

  return ( 
    <AsyncTypeahead
      filterBy={turnOffFilterBy}
      isLoading={isLoading}
      minLength={3}
      onSearch={handleSearch}
      options={options}
      allowNew 
      labelKey="name"
      ref={parentRef}
      renderMenuItemChildren={(option, { text }) => (
        <>
          { option && <>
            {option?.name}
            <div><small><Highlighter search={text}>{option.term}</Highlighter></small></div>
            </>
          }
        </>
      )}
      {...rest}
    />
  );
}

export default ShowTaxInput;