import React from 'react';
import Post from './Post';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';

const PostGrid = ({ posts }) => {
  return ( 
    <Row as='ul' xs={1} md={3} lg={4} className="p-0">
    {posts && posts.map( post => (
      <Col key={post.ID} className="mb">
        <Post post={post} />
      </Col>
    ))}
    </Row>
  );
}

export default PostGrid;