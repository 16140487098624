import { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import postService from "../services/postService";

const ShowNameInput = ({parentRef, ...rest}) => {

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const turnOffFilterBy = () => true;

  const handleSearch = async (query) => {
    setIsLoading(true)
    const res = await postService.getPosts({searchField:"meta", metaKey:"tsl-show-name", searchTerm:query})
    if(res){
      const posts = res.data.items
      const shows = posts.map(p => p.meta['tsl-show-name'][0])
      console.log(posts)
      console.log(shows)
      setOptions(shows)
      setIsLoading(false)
    }
  }

  return ( 
    <AsyncTypeahead
      filterBy={turnOffFilterBy}
      isLoading={isLoading}
      minLength={3}
      onSearch={handleSearch}
      options={options}
      allowNew 
      ref={parentRef}
      {...rest}
    />
  );
}

export default ShowNameInput;