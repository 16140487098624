import React from 'react'
import Form from "./common/form";
const Joi = require('joi-browser');

export default class LoginForm extends Form {

  state = {
    data: { email: "", password: "" },
    errors: {}
  };

  schema = {
    email: Joi.string()
      .email()
      .min(6)
      .max(65)
      .required()
      .label('Email'),
    password: Joi.string()
      .min(6)
      .max(24)
      .required()
      .label('Password')
  }

  doSubmit = () => {
    // submit form
  }

  render() {

    return (
      <form className="form" onSubmit={this.handleSubmit}>
      { this.renderInput("email", "Email") }
      { this.renderInput("password", "Şifre", "password") }
      <div className="tac">
        { this.renderButton("Giriş") }
      </div>
    </form>
    )
  }
}




